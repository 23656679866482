import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyADqAnzHPP1Q9-CvYiXV1zGcgm3kjzrBiY",
    authDomain: "faraday-c1987.firebaseapp.com",
    projectId: "faraday-c1987",
    storageBucket: "faraday-c1987.appspot.com",
    messagingSenderId: "1058564985697",
    appId: "1:1058564985697:web:62a783090185f8dfeaeb20"
};
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app)

const publicKey = "BBoUQxXApgksR4Wb0R-U4VceOpXe9iYL4z91Jg_vWOXaOftcF0l8gD4DvmiMTOsDAcj3tOi9sGvlkerym76ogi0"

export const getClientToken = async () => {
    console.log("client token fn1");
    
    let currentToken = "";
    const isSupport = await isSupported()
    try {
        console.log(isSupport, "isSuppo");
        if (isSupport) {
            currentToken = await getToken(messaging, { vapidKey: publicKey });
            if (currentToken) {
                console.log(currentToken, "token");
            } else {
                console.log("No token found , or permission not there");
            }
        }
    } catch (error:any) {
        const err=new Error(error)
        if (err.message.includes('(messaging/permission-blocked)')) {
            console.log("An error occurred while retrieving token. ",err.message );
            getPermission()
        }
    }

    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    });

const getPermission=async()=>{
    const permission = Notification.permission;    
    if (permission === 'granted') {
        console.log("Notification permission is allowed");
    } else if (permission === 'denied') {
        console.log("permission denied");
        Notification.requestPermission().then((result) => {
            if (result === 'granted') {
                console.log("Notification permission granted by user");
            } else {
                console.log("Notification permission denied by user");
    
            }
          });
        
      

    }
}







