import { APICore } from "./apiCore";

const api = new APICore();

function fetchVendor(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: any, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/vendor?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}`;
    return api.get(`${baseUrl}`, '');
}

function searchVendor(params: { jobId: string }) {
    const baseUrl = `/vendor/search/${params.jobId}`;
    return api.get(`${baseUrl}`, '');
}

function fetchVendorForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/vendor/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.create(`${baseUrl}`, "");
}


function fetchSingleVendor(params: any) {
    const baseUrl = `/vendor/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createVendor(body: any) {
    const baseUrl = "/vendor";
    return api.create(`${baseUrl}`, body);
}

function updateVendor(data: any) {
    const baseUrl = `/vendor/${data.id}`;
    return api.update(`${baseUrl}`, { vendor: data.updateBody });
}

function deleteVendor(data: any) {
    const baseUrl = `/vendor/${data.id}`;
    return api.delete(`${baseUrl}`);
}

async function createVendorWithFiles(params: { vendorData: any, logoData?: any, chequeData?: any }) {
    const baseUrl = '/vendor';
    const fileUrl = `file/upload`
    console.log("paramsT", params)
    const createdVendor = await api.create(`${baseUrl}`, { vendor: { ...params?.vendorData } })
    const vendorResponse = createdVendor.data
    const vendor_id = vendorResponse?.response?.vendor?.dataValues.id

    const oldMachine = vendorResponse?.response?.vendor?.dataValues?.machine_details
    if (oldMachine) {
        oldMachine.forEach((item: any) => {
            item.vendor_id = vendor_id
        })
    }



    const newVendor = { ...vendorResponse?.response?.vendor?.dataValues, name: vendorResponse?.response?.vendor?.name, email: vendorResponse?.response?.vendor?.email, mobile: vendorResponse?.response?.vendor?.mobile, machine_details: oldMachine }
    console.log(createdVendor, "createdVendor")

    if (!params.logoData && !params.chequeData) {
        return createdVendor

    }

    let cheque_data_response = null
    let logo_data_response = null
    if (params?.chequeData) {
        params?.chequeData?.append('uploadedBy', newVendor?.user_id)
        params?.chequeData?.append('type', 'Vendor')
        params?.chequeData?.append('typeId', newVendor?.id)
        params?.chequeData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, params?.chequeData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse")
        cheque_data_response = newFileResponse?.data?.response?.id

    }

    if (params?.logoData) {
        params?.logoData?.append('uploadedBy', newVendor?.user_id)
        params?.logoData?.append('type', 'Vendor')
        params?.logoData?.append('typeId', newVendor?.id)
        params?.logoData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, params?.logoData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse2")
        logo_data_response = newFileResponse?.data?.response?.id

    }

    console.log("BeforeUpdate", logo_data_response)
    const updateBody = {
        id: newVendor?.user_id,
        body: {
            ...newVendor,
            file_id: cheque_data_response,
            logo_id: logo_data_response
        }
    }
    console.log("afterUpdate", updateBody)

    const baseVendorUrl = `/vendor/${newVendor?.user_id}`;
    return api.update(`${baseVendorUrl}`, { vendor: { ...updateBody.body } })

}


async function updateVendorWithFiles(params: any) {
    try {
        console.log("paramsss", params)
        const fileUrl = `file/upload`
        const newChequeData = params.chequeData
        const newLogoData = params.logoData
        let newChequeDataResponse = null
        let newLogoDataResponse = null

        if (newChequeData) {
            if (params.updateBody.file_id) {
                const baseUrl = `file/${params.updateBody.file_id}`
                await api.delete(`${baseUrl}`)
            }

            newChequeData?.append('uploadedBy', params?.id)
            newChequeData?.append('type', 'Vendor')
            newChequeData?.append('typeId', params?.id)
            newChequeData?.append('tagId', "8")

            const newFile = await api.create(`${fileUrl}`, newChequeData)
            const newFileResponse = newFile.data
            console.log(newFileResponse, "newFileResponse")
            newChequeDataResponse = newFileResponse?.data?.response?.id

        }
        if (newLogoData) {
            if (params.updateBody.logo_id) {
                const baseUrl = `file/${params.updateBody.logo_id}`
                await api.delete(`${baseUrl}`)
            }

            newLogoData?.append('uploadedBy', params?.id)
            newLogoData?.append('type', 'Vendor')
            newLogoData?.append('typeId', params?.id)
            newLogoData?.append('tagId', "8")

            const newFile = await api.create(`${fileUrl}`, newLogoData)
            const newFileResponse = newFile.data
            console.log(newFileResponse, "newFileResponse1")
            newLogoDataResponse = newFileResponse?.data?.response?.id
        }

        const baseUrl = `/vendor/${params.id}`;
        return api.update(`${baseUrl}`, { vendor: { ...params.updateBody, file_id: newChequeDataResponse ? newChequeDataResponse : params?.updateBody?.file_id, logo_id: newLogoDataResponse ? newLogoDataResponse : params?.updateBody?.logo_id } })
    } catch (err) {
        console.log(err, "errorwithfileupload")
    }
}


export { fetchVendor, createVendor, updateVendor, fetchSingleVendor, fetchVendorForDasbord, deleteVendor, createVendorWithFiles, updateVendorWithFiles, searchVendor };