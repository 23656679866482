import { ActionTypes } from "./constants";

export type WarehouseType = {
    type: 
    | ActionTypes.FETCH_WAREHOUSE_LIST
    | ActionTypes.FETCH_WAREHOUSE_LIST_SUCCESS
    | ActionTypes.FETCH_WAREHOUSE_LIST_FAILURE
    | ActionTypes.RESET_WAREHOUSE_LIST
    | ActionTypes.CREATE_WAREHOUSE
    | ActionTypes.CREATE_WAREHOUSE_SUCCESS
    | ActionTypes.CREATE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_CREATE_WAREHOUSE
    | ActionTypes.UPDATE_WAREHOUSE
    | ActionTypes.UPDATE_WAREHOUSE_SUCCESS
    | ActionTypes.UPDATE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_UPDATE_WAREHOUSE
    | ActionTypes.DELETE_WAREHOUSE
    | ActionTypes.DELETE_WAREHOUSE_SUCCESS
    | ActionTypes.DELETE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_DELETE_WAREHOUSE
    | ActionTypes.FETCH_SINGLE_WAREHOUSE
    | ActionTypes.FETCH_SINGLE_WAREHOUSE_SUCCESS
    | ActionTypes.FETCH_SINGLE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_FETCH_SINGLE_WAREHOUSE
    | ActionTypes.UPDATE_STOCK
    | ActionTypes.UPDATE_STOCK_SUCCESS
    | ActionTypes.UPDATE_STOCK_FAILURE
    | ActionTypes.RESET_STOCK_UPDATE
    | ActionTypes.FETCH_WAREHOUSE_ACTIONS
    | ActionTypes.FETCH_WAREHOUSE_ACTIONS_SUCCESS
    | ActionTypes.FETCH_WAREHOUSE_ACTIONS_FAILURE
    | ActionTypes.RESET_WAREHOUSE_ACTIONS
    | ActionTypes.FETCH_STOCK_COMMENT_LIST
    | ActionTypes.FETCH_STOCK_COMMENT_SUCCESS
    | ActionTypes.FETCH_STOCK_COMMENT_FAILURE
    | ActionTypes.RESET_STOCK_COMMENT
    | ActionTypes.FETCH_COMMENT_TRANSACTION_LIST
    | ActionTypes.FETCH_COMMENT_TRANSACTION_SUCCESS
    | ActionTypes.FETCH_COMMENT_TRANSACTION_FAILURE
    | ActionTypes.RESET_COMMENT_TRANSACTION
    | ActionTypes.SET_WAREHOUSE_ERROR
    payload: {} | string
}

export const getWarehouse = (data:any):WarehouseType=>({
    type: ActionTypes.FETCH_WAREHOUSE_LIST,
    payload: {data}
})

export const resetWarehouse = () : WarehouseType =>({
    type: ActionTypes.RESET_WAREHOUSE_LIST,
    payload: {}
})

export const fetchWarehouseResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.FETCH_WAREHOUSE_LIST_SUCCESS:
            return {
                type: ActionTypes.FETCH_WAREHOUSE_LIST_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_WAREHOUSE_LIST_FAILURE:
            return {
                type: ActionTypes.FETCH_WAREHOUSE_LIST_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_WAREHOUSE_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}


export const getWarehouseActions = (data:any):WarehouseType=>({
    type: ActionTypes.FETCH_WAREHOUSE_ACTIONS,
    payload: {data}
})

export const resetWarehouseActions = () : WarehouseType =>({
    type: ActionTypes.RESET_WAREHOUSE_ACTIONS,
    payload: {}
})

export const fetchWarehouseActionResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.FETCH_WAREHOUSE_ACTIONS_SUCCESS:
            return {
                type: ActionTypes.FETCH_WAREHOUSE_ACTIONS_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_WAREHOUSE_ACTIONS_FAILURE:
            return {
                type: ActionTypes.FETCH_WAREHOUSE_ACTIONS_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_WAREHOUSE_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}


export const getWarehouseComments = (data:any):WarehouseType=>({
    type: ActionTypes.FETCH_STOCK_COMMENT_LIST,
    payload: {data}
})

export const resetWarehouseComments = () : WarehouseType =>({
    type: ActionTypes.RESET_STOCK_COMMENT,
    payload: {}
})

export const fetchWarehouseCommentsResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.FETCH_STOCK_COMMENT_SUCCESS:
            return {
                type: ActionTypes.FETCH_STOCK_COMMENT_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_STOCK_COMMENT_FAILURE:
            return {
                type: ActionTypes.FETCH_STOCK_COMMENT_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_WAREHOUSE_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}



export const getCommentTransaction = (data:any):WarehouseType=>({
    type: ActionTypes.FETCH_COMMENT_TRANSACTION_LIST,
    payload: {data}
})

export const resetCommentTransaction = () : WarehouseType =>({
    type: ActionTypes.RESET_COMMENT_TRANSACTION,
    payload: {}
})

export const fetchCommentTransactionResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.FETCH_COMMENT_TRANSACTION_SUCCESS:
            return {
                type: ActionTypes.FETCH_COMMENT_TRANSACTION_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_COMMENT_TRANSACTION_FAILURE:
            return {
                type: ActionTypes.FETCH_COMMENT_TRANSACTION_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_WAREHOUSE_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}


export const createWarehouse = (data:any):WarehouseType=>({
    type: ActionTypes.CREATE_WAREHOUSE,
    payload: {data}
})

export const resetCreateWarehouse = ():WarehouseType =>({
    type: ActionTypes.RESET_CREATE_WAREHOUSE,
    payload: {}
})

export const createWarehouseResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.CREATE_WAREHOUSE_SUCCESS:
            return {
                type: ActionTypes.CREATE_WAREHOUSE_SUCCESS,
                payload: {data}
            }
        case ActionTypes.CREATE_WAREHOUSE_FAILURE:
            return {
                type: ActionTypes.CREATE_WAREHOUSE_FAILURE,
                payload: {error:data}
            }
        default:
            return {
                type: ActionTypes.SET_WAREHOUSE_ERROR,
                payload: { actionType, error: "Error While creating!" },
            };
    }
}


export const updateWarehouse = (data:any):WarehouseType=>({
    type: ActionTypes.UPDATE_WAREHOUSE,
    payload: {data}
})

export const resetUpdateWarehouse = ():WarehouseType =>({
    type: ActionTypes.RESET_UPDATE_WAREHOUSE,
    payload: {}
})


export const updateWarehouseResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.UPDATE_WAREHOUSE_SUCCESS:
            return {
                type: ActionTypes.UPDATE_WAREHOUSE_SUCCESS,
                payload: {data}
            }
        case ActionTypes.UPDATE_WAREHOUSE_FAILURE:
            return {
                type: ActionTypes.UPDATE_WAREHOUSE_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_WAREHOUSE_ERROR,
                    payload: { actionType, error: "Error While updating!" },
                };
    }
}


export const updateWarehouseStock = (data:any):WarehouseType=>({
    type: ActionTypes.UPDATE_STOCK,
    payload: {data}
})

export const resetUpdateWarehouseStock = ():WarehouseType =>({
    type: ActionTypes.RESET_STOCK_UPDATE,
    payload: {}
})


export const updateWarehouseStockResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.UPDATE_STOCK_SUCCESS:
            return {
                type: ActionTypes.UPDATE_STOCK_SUCCESS,
                payload: {data}
            }
        case ActionTypes.UPDATE_STOCK_FAILURE:
            return {
                type: ActionTypes.UPDATE_STOCK_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_WAREHOUSE_ERROR,
                    payload: { actionType, error: "Error While updating!" },
                };
    }
}

export const deleteWarehouse = (data:any):WarehouseType=>({
    type: ActionTypes.DELETE_WAREHOUSE,
    payload: {data}
})

export const resetDeleteWarehouse = ():WarehouseType =>({
    type: ActionTypes.RESET_DELETE_WAREHOUSE,
    payload: {}
})

export const deleteWarehouseResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.DELETE_WAREHOUSE_SUCCESS:
            return {
                type: ActionTypes.DELETE_WAREHOUSE_SUCCESS,
                payload: {data}
            }
        case ActionTypes.DELETE_WAREHOUSE_FAILURE:
            return {
                type: ActionTypes.DELETE_WAREHOUSE_FAILURE,
                payload: {error:data}
            }
        default:
            return {
                type: ActionTypes.SET_WAREHOUSE_ERROR,
                payload: { actionType, error: "Error While deleting!" },
            };
    }
}

export const fetchSingleWarehouse = (data:any):WarehouseType=>({
    type: ActionTypes.FETCH_SINGLE_WAREHOUSE,
    payload: {data}
})

export const resetFetchSingleWarehouse = ():WarehouseType =>({
    type: ActionTypes.RESET_FETCH_SINGLE_WAREHOUSE,
    payload: {}
})


export const fetchSingleWarehouseResponse = (actionType:string, data:any):WarehouseType=>{
    switch(actionType){
        case ActionTypes.FETCH_SINGLE_WAREHOUSE_SUCCESS:
            return {
                type: ActionTypes.FETCH_SINGLE_WAREHOUSE_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_SINGLE_WAREHOUSE_FAILURE:
            return {
                type: ActionTypes.FETCH_SINGLE_WAREHOUSE_FAILURE,
                payload: {error:data}
            }
        default:
            return {
                type: ActionTypes.SET_WAREHOUSE_ERROR,
                payload: { actionType, error: "Error While fetching data!" },
            };
    }
}