import { VendorResponse } from "redux/types/DataTypes";
import { ActionTypes } from './constants';

export type VendorActionType = {
    type:
    | ActionTypes.CREATE_VENDOR
    | ActionTypes.FETCH_VENDOR
    | ActionTypes.SET_VENDOR
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_VENDOR
    | ActionTypes.FETCH_SINGLE_VENDOR
    | ActionTypes.SET_SINGLE_VENDOR
    | ActionTypes.UPDATE_VENDOR_SUCCESS
    | ActionTypes.UPDATE_VENDOR_FAIL
    | ActionTypes.ADD_VENDOR_SUCCESS
    | ActionTypes.ADD_VENDOR_FAIL
    | ActionTypes.DASHBOARD_VENDOR_DATA
    | ActionTypes.SET_DASHBOARD_VENDOR_DATA
    | ActionTypes.DELETE_VENDOR
    | ActionTypes.DELETE_VENDOR_SUCCESS
    | ActionTypes.DELETE_VENDOR_FAIL
    | ActionTypes.UPDATE_VENDOR_WITH_FILES
    | ActionTypes.CREATE_VENDOR_WITH_FILES
    | ActionTypes.CREATE_VENDOR_WITH_FILES_SUCCESS
    | ActionTypes.CREATE_VENDOR_WITH_FILES_FAIL
    | ActionTypes.SEARCH_VENDOR
    | ActionTypes.SEARCH_VENDOR_SUCCESS
    | ActionTypes.SEARCH_VENDOR_FAIL

    payload: {} | string;
}

export const getVendor = (data: any): VendorActionType => ({
    type: ActionTypes.FETCH_VENDOR,
    payload: { data }
})

export const searchVendor = (data: any): VendorActionType => ({
    type: ActionTypes.SEARCH_VENDOR,
    payload: { jobId: data.jobId }
})

export const getSingleVendor = (id: any): VendorActionType => ({
    type: ActionTypes.FETCH_SINGLE_VENDOR,
    payload: { id }
})

export const vendorDashbordData = (data: any) => ({
    type: ActionTypes.DASHBOARD_VENDOR_DATA,
    payload: { data }
})


export const createVendor = (data: any): VendorActionType => ({
    type: ActionTypes.CREATE_VENDOR,
    payload: { data }
})

export const updateVendor = (data: any): VendorActionType => ({
    type: ActionTypes.UPDATE_VENDOR,
    payload: { data }
})

export const vendorReset = () => ({
    type: ActionTypes.RESET_VENDOR,
    payload: {}
})

export const vendorApiResponse = (actionType: string, data: VendorResponse): VendorActionType => {
    // type: ActionTypes.SET_VENDOR,
    // payload: { data }
    switch (actionType) {
        case ActionTypes.FETCH_VENDOR:
            return {
                type: ActionTypes.SET_VENDOR,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_VENDOR:
            return {
                type: ActionTypes.SET_SINGLE_VENDOR,
                payload: { data }
            }
        case ActionTypes.DASHBOARD_VENDOR_DATA:
            return {
                type: ActionTypes.SET_DASHBOARD_VENDOR_DATA,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

export const searchVendorAPIResponse = (actionType: string, data: any): VendorActionType => {
    switch (actionType) {
        case ActionTypes.SEARCH_VENDOR_SUCCESS:
            return {
                type: ActionTypes.SEARCH_VENDOR_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.SEARCH_VENDOR_FAIL:
            return {
                type: ActionTypes.SEARCH_VENDOR_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const vendorUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_VENDOR_SUCCESS,
        payload: { data }
    }
}

export const vendorUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_VENDOR_FAIL,
        payload: { actionType, error }
    }
}


export const vendorAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_VENDOR_SUCCESS,
        payload: { data }
    }
}


export const vendorAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_VENDOR_FAIL,
        payload: { actionType, error }
    }
}


export const vendorApiError = (actionType: string, error: any): VendorActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})


export const deleteVendor = (data: any): VendorActionType => ({
    type: ActionTypes.DELETE_VENDOR,
    payload: { data }
})


export const vendorDeleteResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.DELETE_VENDOR_SUCCESS,
        payload: { actionType, data }
    }
}
export const vendorDeleteError = (actionType: string, error: any): VendorActionType => ({
    type: ActionTypes.DELETE_VENDOR_FAIL,
    payload: { actionType, error }
})


// VENDOR UPLOAD WITH FILES

export const createVendorWithFiles = (data: any): VendorActionType => ({
    type: ActionTypes.CREATE_VENDOR_WITH_FILES,
    payload: { data }
})
export const createVendorWithFilesSuccess = (actionType: string, data: any): VendorActionType => ({
    type: ActionTypes.CREATE_VENDOR_WITH_FILES_SUCCESS,
    payload: { actionType, data }
})
export const createVendorWithFilesFail = (actionType: string, error: any): VendorActionType => ({
    type: ActionTypes.CREATE_VENDOR_WITH_FILES_FAIL,
    payload: { actionType, error }
})

export const updateVendorWithFiles = (data: any): VendorActionType => ({
    type: ActionTypes.UPDATE_VENDOR_WITH_FILES,
    payload: { data }
})


export const vendorUpdateResponseWithFiles = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_VENDOR_SUCCESS_WITH_FILES,
        payload: { data }
    }
}


export const vendorUpdateErrorWithFiles = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_VENDOR_FAIL_WITH_FILES,
        payload: { actionType, error }
    }
}
