import { ActionTypes } from './constants';


const INIT_STATE = {
    warehouse: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    createWarehouse: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    updateWarehouse: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    deleteWarehouse: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    warehouseDetails: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    updateStock: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    warehouseActions: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    stockComment: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    stockTransaction: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    commonError: {
        loading: false,
        error: null,
        success: false,
        data: null
    }
}

type warehouseType = {
    type:
    | ActionTypes.FETCH_WAREHOUSE_LIST
    | ActionTypes.FETCH_WAREHOUSE_LIST_SUCCESS
    | ActionTypes.FETCH_WAREHOUSE_LIST_FAILURE
    | ActionTypes.RESET_WAREHOUSE_LIST
    | ActionTypes.FETCH_SINGLE_WAREHOUSE
    | ActionTypes.FETCH_SINGLE_WAREHOUSE_SUCCESS
    | ActionTypes.FETCH_SINGLE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_FETCH_SINGLE_WAREHOUSE
    | ActionTypes.CREATE_WAREHOUSE
    | ActionTypes.CREATE_WAREHOUSE_SUCCESS
    | ActionTypes.CREATE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_CREATE_WAREHOUSE
    | ActionTypes.UPDATE_WAREHOUSE
    | ActionTypes.UPDATE_WAREHOUSE_SUCCESS
    | ActionTypes.UPDATE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_UPDATE_WAREHOUSE
    | ActionTypes.DELETE_WAREHOUSE
    | ActionTypes.DELETE_WAREHOUSE_SUCCESS
    | ActionTypes.DELETE_WAREHOUSE_FAILURE
    | ActionTypes.RESET_DELETE_WAREHOUSE
    | ActionTypes.UPDATE_STOCK
    | ActionTypes.UPDATE_STOCK_SUCCESS
    | ActionTypes.UPDATE_STOCK_FAILURE
    | ActionTypes.RESET_STOCK_UPDATE
    | ActionTypes.FETCH_WAREHOUSE_ACTIONS
    | ActionTypes.FETCH_WAREHOUSE_ACTIONS_SUCCESS
    | ActionTypes.FETCH_WAREHOUSE_ACTIONS_FAILURE
    | ActionTypes.RESET_WAREHOUSE_ACTIONS
    | ActionTypes.RESET_STOCK_UPDATE
    | ActionTypes.FETCH_STOCK_COMMENT_LIST
    | ActionTypes.FETCH_STOCK_COMMENT_SUCCESS
    | ActionTypes.FETCH_STOCK_COMMENT_FAILURE
    | ActionTypes.RESET_STOCK_COMMENT
    | ActionTypes.FETCH_COMMENT_TRANSACTION_LIST
    | ActionTypes.FETCH_COMMENT_TRANSACTION_SUCCESS
    | ActionTypes.FETCH_COMMENT_TRANSACTION_FAILURE
    | ActionTypes.RESET_COMMENT_TRANSACTION
    | ActionTypes.SET_WAREHOUSE_ERROR

    payload: {
        data?: {} | any;
        error?: {} | string
    }
}

export default function WarehouseReducer(state = INIT_STATE, action: warehouseType) {
    switch (action.type) {
        case ActionTypes.FETCH_WAREHOUSE_LIST:
            return { ...state, warehouse: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_WAREHOUSE_LIST_SUCCESS:
            return { ...state, warehouse: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_WAREHOUSE_LIST_FAILURE:
            return { ...state, warehouse: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.FETCH_SINGLE_WAREHOUSE:
            return { ...state, warehouseDetails: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_SINGLE_WAREHOUSE_SUCCESS:
            return { ...state, warehouseDetails: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_SINGLE_WAREHOUSE_FAILURE:
            return { ...state, warehouseDetails: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_FETCH_SINGLE_WAREHOUSE:
            return { ...state, warehouseDetails: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.CREATE_WAREHOUSE:
            return { ...state, createWarehouse: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.CREATE_WAREHOUSE_SUCCESS:
            return { ...state, createWarehouse: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.CREATE_WAREHOUSE_FAILURE:
            return { ...state, createWarehouse: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_CREATE_WAREHOUSE:
            return { ...state, createWarehouse: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.UPDATE_WAREHOUSE:
            return { ...state, updateWarehouse: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.UPDATE_WAREHOUSE_SUCCESS:
            return { ...state, updateWarehouse: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.UPDATE_WAREHOUSE_FAILURE:
            return { ...state, updateWarehouse: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_UPDATE_WAREHOUSE:
            return { ...state, updateWarehouse: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.UPDATE_STOCK:
            return { ...state, updateStock: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.UPDATE_STOCK_SUCCESS:
            return { ...state, updateStock: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.UPDATE_STOCK_FAILURE:
            return { ...state, updateStock: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_STOCK_UPDATE:
            return { ...state, updateStock: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.DELETE_WAREHOUSE:
            return { ...state, deleteWarehouse: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.DELETE_WAREHOUSE_SUCCESS:
            return { ...state, deleteWarehouse: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.DELETE_WAREHOUSE_FAILURE:
            return { ...state, deleteWarehouse: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_DELETE_WAREHOUSE:
            return { ...state, deleteWarehouse: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.FETCH_WAREHOUSE_ACTIONS:
            return { ...state, warehouseActions: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_WAREHOUSE_ACTIONS_SUCCESS:
            return { ...state, warehouseActions: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_WAREHOUSE_ACTIONS_FAILURE:
            return { ...state, warehouseActions: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.FETCH_STOCK_COMMENT_LIST:
            return { ...state, stockComment: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_STOCK_COMMENT_SUCCESS:
            return { ...state, stockComment: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_STOCK_COMMENT_FAILURE:
            return { ...state, stockComment: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.FETCH_COMMENT_TRANSACTION_LIST:
            return { ...state, stockTransaction: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_COMMENT_TRANSACTION_SUCCESS:
            return { ...state, stockTransaction: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_COMMENT_TRANSACTION_FAILURE:
            return { ...state, stockTransaction: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_COMMENT_TRANSACTION:
            return { ...state, stockTransaction: { loading: false, data: null, success: false, error: null } }
        default:
            return { ...state }
    }
}