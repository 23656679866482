export enum ActionTypes{

    FETCH_WAREHOUSE_LIST = "FETCH_WAREHOUSE_LIST",
    FETCH_WAREHOUSE_LIST_SUCCESS = "FETCH_WAREHOUSE_LIST_SUCCESS",
    FETCH_WAREHOUSE_LIST_FAILURE = "FETCH_WAREHOUSE_LIST_FAILURE",
    RESET_WAREHOUSE_LIST = "RESET_WAREHOUSE_LIST",
    
    
    CREATE_WAREHOUSE = "CREATE_WAREHOUSE",
    CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS",
    CREATE_WAREHOUSE_FAILURE = "CREATE_WAREHOUSE_FAILURE",
    RESET_CREATE_WAREHOUSE = "RESET_CREATE_WAREHOUSE",
    
    
    UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE",
    UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS",
    UPDATE_WAREHOUSE_FAILURE = "UPDATE_WAREHOUSE_FAILURE",
    RESET_UPDATE_WAREHOUSE = "RESET_UPDATE_WAREHOUSE",
    
    
    DELETE_WAREHOUSE = "DELETE_WAREHOUSE",
    DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS",
    DELETE_WAREHOUSE_FAILURE = "DELETE_WAREHOUSE_FAILURE",
    RESET_DELETE_WAREHOUSE = "RESET_DELETE_WAREHOUSE",
    
    FETCH_SINGLE_WAREHOUSE = "FETCH_SINGLE_WAREHOUSE",
    FETCH_SINGLE_WAREHOUSE_SUCCESS = "FETCH_SINGLE_WAREHOUSE_SUCCESS",
    FETCH_SINGLE_WAREHOUSE_FAILURE = "FETCH_SINGLE_WAREHOUSE_FAILURE",
    RESET_FETCH_SINGLE_WAREHOUSE = "RESET_FETCH_SINGLE_WAREHOUSE",

    FETCH_WAREHOUSE_ACTIONS = "FETCH_WAREHOUSE_ACTIONS",
    FETCH_WAREHOUSE_ACTIONS_SUCCESS = "FETCH_WAREHOUSE_ACTIONS_SUCCESS",
    FETCH_WAREHOUSE_ACTIONS_FAILURE = "FETCH_WAREHOUSE_ACTIONS_FAILURE",
    RESET_WAREHOUSE_ACTIONS = "RESET_WAREHOUSE_ACTIONS",


    FETCH_STOCK_COMMENT_LIST  = "FETCH_STOCK_COMMENT_LIST",
    FETCH_STOCK_COMMENT_SUCCESS = "FETCH_STOCK_COMMENT_SUCCESS",
    FETCH_STOCK_COMMENT_FAILURE = "FETCH_STOCK_COMMENT_FAILURE",
    RESET_STOCK_COMMENT = "RESET_STOCK_COMMENT",

    FETCH_COMMENT_TRANSACTION_LIST  = "FETCH_COMMENT_TRANSACTION_LIST",
    FETCH_COMMENT_TRANSACTION_SUCCESS = "FETCH_COMMENT_TRANSACTION_SUCCESS",
    FETCH_COMMENT_TRANSACTION_FAILURE = "FETCH_COMMENT_TRANSACTION_FAILURE",
    RESET_COMMENT_TRANSACTION = "RESET_COMMENT_TRANSACTION",


    UPDATE_STOCK = "UPDATE_STOCK",
    UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS",
    UPDATE_STOCK_FAILURE = "UPDATE_STOCK_FAILURE",
    RESET_STOCK_UPDATE = "RESET_STOCK_UPDATE",
    
    SET_WAREHOUSE_ERROR = "SET_WAREHOUSE_ERROR",
    
    }