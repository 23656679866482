import { ActionTypes } from './constants';


const INIT_STATE = {
    project: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    createProject: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    projectDetail: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    updateProject: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    projectTaskType: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    projectTask: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    createProjectTask: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    taskDetail: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    updateProjectTask: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    bulkUpdatedStatus: {
        loading: false,
        error: null,
        success: null,
        data: null,
    },

    taskError: {},
    projectError: {},
    assignedTask: {},

    completeProject: {},
    countAssignedTask: null,
    myProject: {},
    taskCount: {},
    projectByMonth: {},
    projectWithValue: {},
    projectWithOngoing: {},
    projectWithCompleted: {},
    funnelData: {},
    projectByLocation: {},

    projectSuccess: false,
    taskSuccess: false,
    projectTaskStatus: {},
    location: {},
    projectStatusList: {},
    deleteProjectSuccess: false,

}


type ProjectActionType = {
    type:
    | ActionTypes.FETCH_PROJECT_LIST_RESET_STATE
    | ActionTypes.FETCH_PROJECT_LIST
    | ActionTypes.FETCH_PROJECT_LIST_FAIL
    | ActionTypes.FETCH_PROJECT_LIST_SUCCESS
    | ActionTypes.FETCH_PROJECT_DETAIL_RESET_STATE
    | ActionTypes.FETCH_PROJECT_DETAIL
    | ActionTypes.FETCH_PROJECT_DETAIL_FAIL
    | ActionTypes.FETCH_PROJECT_DETAIL_SUCCESS
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK_SUCCESS
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK_FAIL
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK_RESET_STATE
    | ActionTypes.CREATE_PROJECT_TASK
    | ActionTypes.CREATE_PROJECT_TASK_SUCCESS
    | ActionTypes.CREATE_PROJECT_TASK_FAIL
    | ActionTypes.CREATE_PROJECT_TASK_RESET_STATE
    | ActionTypes.SET_ERROR
    | ActionTypes.CREATE_PROJECT
    | ActionTypes.CREATE_PROJECT_SUCCESS
    | ActionTypes.CREATE_PROJECT_FAIL
    | ActionTypes.UPDATE_PROJECT_TASK
    | ActionTypes.UPDATE_PROJECT_TASK_FAIL
    | ActionTypes.UPDATE_PROJECT_TASK_SUCCESS
    | ActionTypes.UPDATE_PROJECT_TASK_RESET_STATE
    | ActionTypes.FETCH_SINGLE_TASK
    | ActionTypes.FETCH_SINGLE_TASK_SUCCESS
    | ActionTypes.FETCH_SINGLE_TASK_FAIL
    | ActionTypes.FETCH_SINGLE_TASK_RESET_STATE
    | ActionTypes.SET_ASSIGNED_TASK
    | ActionTypes.SET_ASSIGNED_TASK_COUNT
    | ActionTypes.FETCH_CUSTOMER_PROJECT
    | ActionTypes.SET_CUSTOMER_PROJECT
    | ActionTypes.UPDATE_PROJECT
    | ActionTypes.UPDATE_PROJECT_RESET_STATE
    | ActionTypes.UPDATE_PROJECT_SUCCESS
    | ActionTypes.UPDATE_PROJECT_FAIL
    | ActionTypes.SET_TASK_COUNT_BASED_ON_USER
    | ActionTypes.SET_PROJECT_STATUS
    | ActionTypes.SET_PROJECT_MONTH_DATA
    | ActionTypes.SET_COMPLETE_PROJECT
    | ActionTypes.SET_PROJECTS_WITH_VALUE
    | ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE
    | ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE
    | ActionTypes.SET_PROJECT_BY_LOCATION
    | ActionTypes.SET_FUNNEL_DATA
    | ActionTypes.CREATE_PROJECT_RESET_STATE
    | ActionTypes.DELETE_PROJECT
    | ActionTypes.DELETE_PROJECT_SUCCESS
    | ActionTypes.DELETE_PROJECT_FAIL
    | ActionTypes.SET_PROJECT_LOCATION
    | ActionTypes.SET_PROJECT_TASK_STATUS
    | ActionTypes.FETCH_JOB_TYPE
    | ActionTypes.FETCH_JOB_TYPE_SUCCESS
    | ActionTypes.FETCH_JOB_TYPE_FAIL
    | ActionTypes.FETCH_JOB_TYPE_RESET_STATE
    | ActionTypes.BULK_UPDATE_PROJECT_TASK_SUCCESS
    | ActionTypes.BULK_UPDATE_PROJECT_TASK_FAIL
    payload: {
        actionType?: string;
        data?: {} | any;
        error?: string;
    }
}


const ProjectTaskReducer = (state: any = INIT_STATE, action: ProjectActionType) => {
    switch (action.type) {

        case ActionTypes.FETCH_PROJECT_LIST_RESET_STATE:
            return { ...state, project: { loading: false, error: null, success: false } }
        case ActionTypes.FETCH_PROJECT_LIST:
            return { ...state, project: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.FETCH_PROJECT_LIST_SUCCESS:
            return { ...state, project: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.FETCH_PROJECT_LIST_FAIL:
            return { ...state, project: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.CREATE_PROJECT_RESET_STATE:
            return { ...state, createProject: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.CREATE_PROJECT:
            return { ...state, createProject: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.CREATE_PROJECT_SUCCESS:
            return { ...state, createProject: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.CREATE_PROJECT_FAIL:
            return { ...state, createProject: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.FETCH_PROJECT_DETAIL_RESET_STATE:
            return { ...state, projectDetail: { loading: false, error: null, success: false } }
        case ActionTypes.FETCH_PROJECT_DETAIL:
            return { ...state, projectDetail: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.FETCH_PROJECT_DETAIL_SUCCESS:
            return { ...state, projectDetail: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.FETCH_PROJECT_DETAIL_FAIL:
            return { ...state, projectDetail: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.UPDATE_PROJECT_RESET_STATE:
            return { ...state, updateProject: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.UPDATE_PROJECT:
            return { ...state, updateProject: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.UPDATE_PROJECT_SUCCESS:
            return { ...state, updateProject: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.UPDATE_PROJECT_FAIL:
            return { ...state, updateProject: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.FETCH_SINGLE_PROJECT_TASK_RESET_STATE:
            return { ...state, projectTask: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.FETCH_SINGLE_PROJECT_TASK:
            return { ...state, projectTask: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.FETCH_SINGLE_PROJECT_TASK_SUCCESS:
            return { ...state, projectTask: { loading: false, error: null, success: true, data: action.payload.data }, bulkUpdatedStatus: { loading: true, error: null, success: null, data: null } }
        case ActionTypes.FETCH_SINGLE_PROJECT_TASK_FAIL:
            return { ...state, projectTask: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.FETCH_JOB_TYPE_RESET_STATE:
            return { ...state, projectTaskType: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.FETCH_JOB_TYPE:
            return { ...state, projectTaskType: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.FETCH_JOB_TYPE_SUCCESS:
            return { ...state, projectTaskType: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.FETCH_JOB_TYPE_FAIL:
            return { ...state, projectTaskType: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.CREATE_PROJECT_TASK_RESET_STATE:
            return { ...state, createProjectTask: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.CREATE_PROJECT_TASK:
            return { ...state, createProjectTask: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.CREATE_PROJECT_TASK_SUCCESS:
            return { ...state, createProjectTask: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.CREATE_PROJECT_TASK_FAIL:
            return { ...state, createProjectTask: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.FETCH_SINGLE_TASK_RESET_STATE:
            return { ...state, taskDetail: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.FETCH_SINGLE_TASK:
            return { ...state, taskDetail: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.FETCH_SINGLE_TASK_SUCCESS:
            return { ...state, taskDetail: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.FETCH_SINGLE_TASK_FAIL:
            return { ...state, taskDetail: { loading: false, error: action.payload.error, success: false, data: null } }

        case ActionTypes.UPDATE_PROJECT_TASK_RESET_STATE:
            return { ...state, updateProjectTask: { loading: false, error: null, success: false, data: null } }
        case ActionTypes.UPDATE_PROJECT_TASK:
            return { ...state, updateProjectTask: { loading: true, error: null, success: false, data: null } }
        case ActionTypes.UPDATE_PROJECT_TASK_SUCCESS:
            return { ...state, updateProjectTask: { loading: false, error: null, success: true, data: action.payload.data } }
        case ActionTypes.UPDATE_PROJECT_TASK_FAIL:
            return { ...state, updateProjectTask: { loading: false, error: action.payload.error, success: false, data: null } }


        case ActionTypes.SET_PROJECT_STATUS:
            return {
                ...state, projectStatusList: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_ASSIGNED_TASK:
            return {
                ...state, assignedTask: action.payload.data, loading: false, taskSuccess: false,
                projectSuccess: false, taskError: {}, projectError: {},
            }
        case ActionTypes.SET_ASSIGNED_TASK_COUNT:
            return {
                ...state, countAssignedTask: action.payload.data, loading: false, taskSuccess: false,
                projectSuccess: false, taskError: {}, projectError: {},
            }
        case ActionTypes.SET_CUSTOMER_PROJECT:
            return {
                ...state, myProject: action.payload.data, project: { response: { projects: action.payload.data?.response?.project } }, loading: false, taskSuccess: false,
                projectSuccess: false, taskError: {}, projectError: {},
            }
        case ActionTypes.SET_TASK_COUNT_BASED_ON_USER:
            return {
                ...state, taskCount: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_PROJECT_MONTH_DATA:
            return {
                ...state, projectByMonth: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_COMPLETE_PROJECT:
            return {
                ...state, completeProject: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_PROJECTS_WITH_VALUE:
            return {
                ...state, projectWithValue: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE:
            return {
                ...state, projectWithOngoing: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE:
            return {
                ...state, projectWithCompleted: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_PROJECT_BY_LOCATION:
            return {
                ...state, projectByLocation: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_FUNNEL_DATA:
            return {
                ...state, funnelData: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_ERROR:
            return { ...state, projectError: action.payload.error, taskError: action.payload.error, taskSuccess: false, projectSuccess: false }
        case ActionTypes.DELETE_PROJECT_SUCCESS:
            return { ...state, deleteProjectSuccess: true, error: {} };
        case ActionTypes.DELETE_PROJECT_FAIL:
            return { ...state, deleteProjectSuccess: false, error: action.payload.error, };
        case ActionTypes.SET_PROJECT_LOCATION:
            return { ...state, loading: false, updateSuccess: false, success: false, location: action.payload.data };
        case ActionTypes.SET_PROJECT_TASK_STATUS:
            return { ...state, loading: false, updateSuccess: false, success: false, projectTaskStatus: action.payload.data };
        case ActionTypes.BULK_UPDATE_PROJECT_TASK_SUCCESS:
            return { ...state, bulkUpdatedStatus: { loading: false, error: {}, success: true, data: {} } };
        case ActionTypes.BULK_UPDATE_PROJECT_TASK_FAIL:
            return { ...state, bulkUpdatedStatus: { loading: true, error: action.payload.data, success: false, data: {} } };
        default:
            return { ...state }
    }
}

export default ProjectTaskReducer;