import { APICore } from "./apiCore";

const api = new APICore();
//
function fetchNotification(params: { userId: any, userType: any, typeId?: string, limit?: any }) {
    const url = `/notification/notif/all?user_id=${params.userId}&user_type=${params.userType}&type_id=${params.typeId ? params.typeId : ''}&limit=${params.limit ? params.limit : ''}`
    return api.get(`${url}`, "");
}

function updateNotification(params: { userId: string, id: string }) {
    const url = `/notification/update/status?user_id=${params.userId}&id=${params.id}`
    return api.create(`${url}`, "");
}
function updateAllNotification(params: { userId: string }) {
    const url = `/notification/update/allstatus?user_id=${params.userId}`
    return api.update(`${url}`, "");
}


export { fetchNotification, updateNotification, updateAllNotification }