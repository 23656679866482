import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchReport } from "helpers";
import { reportApiResponse, reportApiError } from "./actions";
import { ActionTypes } from './constants';

function* report(params: any): SagaIterator {
    try {
        const response = yield call(fetchReport, { startDate: params.payload.data.startDate, endDate: params.payload.data.endDate });
        const reportData = yield response.data;
        yield put(reportApiResponse(ActionTypes.FETCH_REPORT, reportData));
    } catch (error: any) {
        yield put(reportApiError(ActionTypes.FETCH_REPORT, error));
    }
}

export function* watchReport() {
    yield takeEvery(ActionTypes.FETCH_REPORT, report);
}

export default watchReport;
