import { ActionTypes as FileActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    files: {},
    error: {},
    success: false,
    message: null,
    tag: {},
    filterFiles: {},
    singleFile: {},
    delSuccess: false,
    singleFileData: {},
    delFileSuccess: false,
    fileUploadSuccess: false,
    updateSuccess:false,
    updateError:null
}


type ActionTypes = {
    type:
    | FileActionTypes.FETCH_FILES
    | FileActionTypes.SET_FILES
    | FileActionTypes.SET_SINGLE_FILE_BY_ID
    | FileActionTypes.SET_ERROR
    | FileActionTypes.UPLOAD_FILE
    | FileActionTypes.UPLOAD_FILE_SUCCESS
    | FileActionTypes.UPLOAD_FILE_FAIL
    | FileActionTypes.DOWNLOAD_FILE_BY_ID
    | FileActionTypes.DOWNLOAD_FILE_BY_PATH
    | FileActionTypes.DOWNLOAD_FILE_SUCCESS
    | FileActionTypes.DOWNLOAD_FILE_FAIL
    | FileActionTypes.DELETE_FAIL
    | FileActionTypes.DELETE_SUCCESS
    | FileActionTypes.DELETE_FAIL
    | FileActionTypes.SET_TAG
    | FileActionTypes.RESET
    | FileActionTypes.SET_FILES_BY_ID
    | FileActionTypes.RESET_FILE_SUCCESS
    | FileActionTypes.RESET_SINGLE_FILE_STATE
    | FileActionTypes.RESET_DELETE_FILE_SUCCESS
    | FileActionTypes.RESET_FILE_UPLOAD_SUCCESS
    | FileActionTypes.UPDATE_FILE_SUCCESS
    | FileActionTypes.UPDATE_FILE_FAIL
    | FileActionTypes.RESET_FILE_UPDATE
    payload: {
        actionType?: string;
        data?: {},
        error?: {},
    }
}



const FilesReducer = (state: any = INIT_STATE, actionTypes: ActionTypes) => {
    switch (actionTypes.type) {
        case FileActionTypes.SET_FILES:
            return { ...state, files: actionTypes.payload.data, loading: false, success: false, error: {}, message: "", delFileSuccess: false }
        case FileActionTypes.SET_FILES_BY_ID:
            return { ...state, filterFiles: actionTypes.payload.data, loading: false, success: false, error: {}, message: "", files: {}, delFileSuccess: false }
        case FileActionTypes.SET_TAG:
            return { ...state, tag: actionTypes.payload.data, loading: false, success: false, error: {}, message: "" }
        case FileActionTypes.SET_SINGLE_FILE_BY_ID:
            return { ...state, singleFileData: actionTypes.payload.data, loading: false, success: false, error: {}, message: "" }
        case FileActionTypes.UPLOAD_FILE_SUCCESS:
            return { ...state, success: true, fileUploadSuccess: true, message: "File uploaded successfully", singleFile: actionTypes.payload.data, error: {}, delFileSuccess: false }
        case FileActionTypes.RESET_FILE_UPLOAD_SUCCESS:
            return {...state,success:false, fileUploadSuccess:false,message:"",error: {}, delFileSuccess: false }
        case FileActionTypes.DELETE_SUCCESS:
            return { ...state, success: true, message: "File deleted successfully", error: {}, delSuccess: true, delFileSuccess: true }
        case FileActionTypes.RESET_DELETE_FILE_SUCCESS:
            return {...state, success: false, message: "", error: {}, delSuccess: false,delFileSuccess:false }
        case FileActionTypes.DELETE_FAIL:
            return { ...state, success: false, message: "", error: { message: "Won't be able to delete file" } }
        case FileActionTypes.UPLOAD_FILE_FAIL:
            return { ...state, success: false, fileUploadSuccess: false, message: "", error: { message: "file upload failed" } }
        case FileActionTypes.DOWNLOAD_FILE_SUCCESS:
            return { ...state, success: false, message: "File downloaded successfully", error: {}, delFalse: false }
        case FileActionTypes.DOWNLOAD_FILE_FAIL:
            return { ...state, success: false, message: "", error: { message: "File download failed" } }
        case FileActionTypes.UPDATE_FILE_SUCCESS:
            return {...state, updateSuccess:true}
        case FileActionTypes.UPDATE_FILE_FAIL:
            return {...state,updateError:actionTypes.payload.error,updateSuccess:false}
        case FileActionTypes.SET_ERROR:
            return { ...state, files: {}, error: actionTypes.payload.error, success: false, message: "" }
        case FileActionTypes.RESET_FILE_SUCCESS:
            return { ...state, success: false, mssage: "" }
        case FileActionTypes.RESET_SINGLE_FILE_STATE:
            return { ...state, singleFileData: {} }
        case FileActionTypes.RESET_FILE_UPDATE:
            return {...state,updateSuccess:false,updateError:null}
        case FileActionTypes.RESET:
            return INIT_STATE
        default:
            return { ...state }
    }
}

export default FilesReducer;