import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchEmployee, createEmployee, updateEmployee, fetchSingleEmployee, employeeByTeam, deleteEmployee,fetchStatus } from "helpers";
import { deleteEmployeeError, deleteEmployeeResponse, employeeAddError, employeeAddResponse, employeeApiError, employeeApiResponse, employeeUpdateResponse, employeUpdateError, getSingleEmployeeImageError, getSingleEmployeeImageResponse,setEmployeeStatus } from "./actions";
import { ActionTypes } from "./constants";

function* employee(params: any): SagaIterator {
    try {
        const response = yield call(fetchEmployee, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, searchBy: params.payload.data.searchBy });
        const employeeData = yield response.data;
        yield put(employeeApiResponse(ActionTypes.FETCH_EMPLOYEE, employeeData));
    } catch (e: any) {
        yield put(employeeApiError(ActionTypes.FETCH_EMPLOYEE, e))
    }
}

function* singleEmployee(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleEmployee, params.payload.id);
        const singleEmployee = response.data;
        yield put(employeeApiResponse(ActionTypes.FETCH_SINGLE_EMPLOYE, singleEmployee))
    } catch (e: any) {
        yield put(employeeApiError(ActionTypes.FETCH_SINGLE_EMPLOYE, e));
    }
}


function* _createEmployee({ payload }: any): SagaIterator {
    try {
        const response = yield call(createEmployee, { employee: payload.data })
        const employeeData = response.data;
        yield put(employeeAddResponse(ActionTypes.CREATE_EMPLOYEE, employeeData))
    } catch (e: any) {
        yield put(employeeAddError(ActionTypes.CREATE_EMPLOYEE, e));
    }
}

function* groupList(params: any): SagaIterator {
    try {
        const response = yield call(employeeByTeam, { limit: params.payload.data.limit });
        const data = response.data;
        yield put(employeeApiResponse(ActionTypes.FETCH_GROUPLIST, data))
    } catch (err) {
        yield put(employeeApiError(ActionTypes.FETCH_GROUPLIST, err));
    }
}

function* _updateEmployee({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateEmployee, { id: payload.data.id, updateBody: payload.data.body })
        const employeeData = response.data;
        // console.log("Updated successfully", employeeData);
        yield put(employeeUpdateResponse(ActionTypes.UPDATE_EMPLOYEE, employeeData));
    } catch (e: any) {
        yield put(employeUpdateError(ActionTypes.UPDATE_EMPLOYEE, e));
    }
}

function* _deleteEmployee({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteEmployee, { id: payload.data.id })
        const deletedEmployee = response.data;
        yield put(deleteEmployeeResponse(ActionTypes.DELETE_EMPLOYEE_SUCCESS, deletedEmployee));
    } catch (e: any) {
        yield put(deleteEmployeeError(ActionTypes.DELETE_EMPLOYEE_FAIL, e));
    }
}

//! get user profile image
function* singleEmployeeImage(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleEmployee, params.payload.id);
        const singleEmployee = response.data;
        yield put(getSingleEmployeeImageResponse(ActionTypes.FETCH_PROFILE_IMAGE_SUCCESS, singleEmployee))
    } catch (e: any) {
        yield put(getSingleEmployeeImageError(ActionTypes.FETCH_PROFILE_IMAGE_FAIL, e));
    }
}

function *fetchEmployeeStatus():SagaIterator{
    try {
        const response = yield call(fetchStatus,"TAG_EMPLOYEE_STATUS");
        const employeeStatus = response.data.data.values;
        console.log(employeeStatus, "response.data.status")
        yield put(setEmployeeStatus(ActionTypes.SET_EMPLOYEE_STATUS, employeeStatus))
    } catch (e: any) {
        yield put(setEmployeeStatus(ActionTypes.SET_EMPLOYEE_STATUS, e));
    }
}



function* watchEmployee() {
    yield takeEvery(ActionTypes.FETCH_EMPLOYEE, employee);
    yield takeEvery(ActionTypes.CREATE_EMPLOYEE, _createEmployee);
    yield takeEvery(ActionTypes.UPDATE_EMPLOYEE, _updateEmployee);
    yield takeEvery(ActionTypes.FETCH_SINGLE_EMPLOYE, singleEmployee);
    yield takeEvery(ActionTypes.FETCH_GROUPLIST, groupList);
    yield takeEvery(ActionTypes.DELETE_EMPLOYEE, _deleteEmployee);
    yield takeEvery(ActionTypes.FETCH_PROFILE_IMAGE, singleEmployeeImage);
    yield takeEvery(ActionTypes.FETCH_EMPLOYEE_STATUS,fetchEmployeeStatus)


}

export default watchEmployee;