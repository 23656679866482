import { APICore } from "./apiCore";

const api = new APICore();


function fetchAllFiles(params: { page: string, limit: string, sortBy: string, sort: string }) {
    const baseUrl = `/file/list?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`
    return api.get(`${baseUrl}`, "");
}

function fetchFilesById(params: { page: string, limit: string, sortBy: string, sort: string, id: string, type: string }) {
    const baseUrl = `/file/${params.id}/${params.type}?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`
    return api.get(`${baseUrl}`, "");
}


function updloadFiles(params: { formData: any }) {
    for (var data of params.formData) {
        console.log(data);
    }
    const baseUrl = `file/upload`
    return api.create(`${baseUrl}`, params.formData)
}


function downloadFileByPath(params: { path: string }) {
    const baseUrl = `file/donwload?path=${params.path}`
    return api.get(`${baseUrl}`, "")
}

function downFileById(params: { id: string }) {
    const baseUrl = `file/download/${params.id}`
    return api.get(`${baseUrl}`, "")
}

function deleteFileById(params: { id: string }) {
    const baseUrl = `file/${params.id}`
    return api.delete(`${baseUrl}`)
}

function getSingleFileById(params: { id: string }) {
    const baseUrl = `file/singleFile/${params.id}`
    return api.get(`${baseUrl}`, "")
}

function updateFileById(params: { id: string,updateBody:any }) {
    const baseUrl = `file/${params.id}`
    return api.update(`${baseUrl}`, params.updateBody)
}



function fetchTags() {
    const baseUrl = `/tag`
    return api.get(`${baseUrl}`, "")
}
export { fetchAllFiles, updloadFiles, downloadFileByPath, downFileById, fetchTags, deleteFileById, fetchFilesById, getSingleFileById,updateFileById };