import { APICore } from "./apiCore";

const api = new APICore();

function fetchProjects(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: string, searchBy?: string }) {
    const baseUrl = `/project?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params.searchBy ? params.searchBy : ''}`
    return api.get(`${baseUrl}`, "");
}

function fetchProjectWithValue(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/project/all/value?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "")
}

function fetchProjectWithOngoing(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/project/ongoing/value?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "")
}

function fetchProjectWithCompleted(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/project/completed/value?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "")
}


function fetchFunnelData(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/project/funnel/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "")
}


function fetchProjectByLocation(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/project/data/location?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "")
}


function fetchProjectStatus(params: any) {
    let baseUrl = `/application/metadata?`
    if (params.data.id) {
        baseUrl = baseUrl + 'id=' + params.data.id;
    } else {
        baseUrl = baseUrl + 'title=' + params.data.title;
    }
    return api.get(`${baseUrl}`, "");
}


function getAssignedTask(params: { id: string, page: string, limit: string, sortBy: string, sort: string, filterBy?: any, userId?: any, filter?: any, searchBy?: string, filterByStatus?: string }) {
    const baseUrl = `/project/assigned-task/${params.id}?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filter=${params.filter ?? ''}&userId=${params.userId ?? ''}&searchBy=${params.searchBy ?? ''}&filterByStatus=${params.filterByStatus}`
    return api.get(`${baseUrl}`, "")
}

function fetchCountAssignedTasks(params: { user_id: number, status: number }) {
    const baseUrl = `/project/assigned-task/count?user_id=${params.user_id}&status=${params.status}`;
    return api.get(`${baseUrl}`, "");
}

function createProject(data: any) {
    const baseUrl = `/project`;
    return api.create(`${baseUrl}`, { project: data.project });
}

function updateProject(data: any) {
    const baseUrl = `/project/${data.id}`
    return api.update(`${baseUrl}`, { project: data.project })
}


function getProjectTasks(params: { id: string, sortBy: string, sort: string,userId?:string }) {
    const baseUrl = `/project/${params.id}/task?sortBy=${params.sortBy}&sort=${params.sort}&userId=${params.userId ?? ''}`;
    return api.get(`${baseUrl}`, "");
}

function createProjectTask(data: any) {
    console.log("data in create projeet", data)
    const baseUrl = `/project/${data.projectID}/task`;
    return api.create(`${baseUrl}`, { task: data.task })
}

function updateProjectTask(data: any) {
    // console.log("Data in update project task", data)
    const baseUrl = `/project/${data.projectID}/task/${data.taskID}`
    // console.log(baseUrl)
    return api.update(`${baseUrl}`, { task: data.task })
}

function getSingleTask(data: any) {
    const baseUrl = `project/${data.projectID}/task/${data.taskID}`
    return api.get(`${baseUrl}`, {})
}

function getProjectById(id: any) {
    const baseUrl = `/project/${id}`;
    return api.get(`${baseUrl}`, {});
}


function getProjectByUserId(params: { id: string, page: string, limit: string, sortBy: string, sort: string }) {
    const baseUrl = `/project/${params.id}/myproject?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`;
    return api.get(`${baseUrl}`, "");
}

function getTaskCountBasedOnUser(params: { user: any, filterBy?: any, filter?: any }) {
    const baseUrl = `/project/taskbased/count?user=${params.user}&filterBy=${params.filterBy}&filter=${params.filter}`
    return api.get(`${baseUrl}`, "");
}

function projectMonthData(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/project/month/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "")
}

function deleteProject(params: any) {
    const baseUrl = `/project/${params.id}`
    return api.delete(`${baseUrl}`)
}

function fetchProjectLoacation() {
    const baseUrl = `/project/location`;
    return api.create(baseUrl, "")
}

function batchUpdateProjectTask(data: any) {
    console.log(data, "bulk data")

    // console.log("Data in update project task", data)
    const baseUrl = `/project/${data.data.payload.data.prjId}/task/update/status`
    // console.log(baseUrl)
    return api.update(`${baseUrl}`, { jobIds: data.data.payload.data.jobIds, status: data.data.payload.data?.status, assignTO: data.data.payload.data?.assignTO, assignType: data.data.payload.data?.assignType })
}


export {
    fetchProjects, createProject, getProjectTasks, createProjectTask,
    updateProjectTask, getProjectById, getSingleTask, getAssignedTask,
    getProjectByUserId, fetchCountAssignedTasks, updateProject, fetchProjectStatus,
    getTaskCountBasedOnUser, projectMonthData, fetchProjectWithValue, fetchProjectWithOngoing, fetchProjectWithCompleted,
    fetchFunnelData, deleteProject, fetchProjectLoacation, fetchProjectByLocation, batchUpdateProjectTask
}
