import { APICore } from "./apiCore";
const api = new APICore();


function fetchWarehouse(params: {
    page: string;
    limit: string;
    sortBy: string;
    sort: string;
    searchBy?: string;
    type?: string;
}) {
    const baseUrl = `/warehouse?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy
        }&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ""}&type=${params.type ?? ""}`;
    return api.get(`${baseUrl}`, "");
}


function fetchWarehouseDetails(params: {
    id: string, page: string;
    limit: string;
    sortBy: string;
    sort: string;
    searchBy?: string;
}) {
    const baseUrl = `/warehouse/${params.id}?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy
        }&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ""}`;
    return api.get(`${baseUrl}`, "");
}

function createWarehouse(data: any) {
    const baseUrl = `/warehouse`;
    return api.create(`${baseUrl}`, { warehouse: data.warehouse });
}

function updateWarehouse(data: any) {
    const baseUrl = `/warehouse/${data.id}`;
    return api.update(`${baseUrl}`, { warehouse: data.warehouse });
}

function updateWarehouseStock(data: any) {
    console.log("stock detials", data)
    const baseUrl = `/warehouse/${data.id}/stock`
    return api.update(`${baseUrl}`, data.formData)
}

function deleteWarehouse(data: any) {
    const baseUrl = `/warehouse/${data.id}`;
    return api.delete(`${baseUrl}`);
}


function fetchWarehouseActions(params: any) {

    let baseUrl = `/application/metadata?`
    baseUrl = baseUrl + 'title=' + params.title;
    return api.get(`${baseUrl}`, "");
}

function fetchWarehouseComments(params: {
    page: string;
    limit: string;
    sortBy: string;
    sort: string;
    searchBy: string;
    warehouse: string;
    filter:string;
}) {
    const baseUrl = `/warehouse/stock/history?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy
        }&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ""}&warehouse=${params.warehouse}&filter=${params.filter ?? ""}`;
    return api.get(`${baseUrl}`, "");
}


function fetchWarehouseTransaction(params: {
    page: string;
    limit: string;
    sortBy: string;
    sort: string;
    transaction: string;
    warehouse: string;
}) {
    const baseUrl = `/warehouse/stock/transaction?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy
        }&sort=${params.sort}&transaction=${params.transaction}&warehouse=${params.warehouse}`;
    return api.get(`${baseUrl}`, "");
}

export {
    fetchWarehouse,
    fetchWarehouseDetails,
    createWarehouse,
    updateWarehouse,
    deleteWarehouse,
    updateWarehouseStock, fetchWarehouseActions,fetchWarehouseComments,fetchWarehouseTransaction
}
