import { ReportResponse } from 'redux/types/DataTypes';
import { ActionTypes } from './constants';

export type ReportActionType = {
    type:
    | ActionTypes.FETCH_REPORT
    | ActionTypes.SET_ERROR
    | ActionTypes.SET_REPORT
    payload: {} | string;
}

//disptach action to get all reports
export const getReport = (data: any): ReportActionType => ({
    type: ActionTypes.FETCH_REPORT,
    payload: { data }
})

//this action will be executed to handle api response
export const reportApiResponse = (
    actionType: string,
    data: ReportResponse
) => {
    switch (actionType) {
        case ActionTypes.FETCH_REPORT:
            return {
                type: ActionTypes.SET_REPORT,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

// this action will be executed when there is error while adding report
export const reportApiError = (actionType: string, error: any): ReportActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})