import { APICore } from "./apiCore";

const api = new APICore();

function fetchReport(params: { startDate: string, endDate: string }) {
    const baseUrl = `/report?startDate=${params.startDate}&endDate=${params.endDate}`;
    console.log(baseUrl)
    return api.get(`${baseUrl}`, "");
}

export { fetchReport }