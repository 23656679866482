export enum ActionTypes {

    FETCH_PROJECT_LIST = "FETCH_PROJECT_LIST",
    FETCH_PROJECT_LIST_SUCCESS = "FETCH_PROJECT_LIST_SUCCESS",
    FETCH_PROJECT_LIST_FAIL = "FETCH_PROJECT_LIST_FAIL",
    FETCH_PROJECT_LIST_RESET_STATE = "FETCH_PROJECT_LIST_RESET_STATE",

    CREATE_PROJECT = "CREATE_PROJECT",
    CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS",
    CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL",
    CREATE_PROJECT_RESET_STATE = "CREATE_PROJECT_RESET_STATE",

    FETCH_PROJECT_DETAIL = "FETCH_PROJECT_DETAIL",
    FETCH_PROJECT_DETAIL_SUCCESS = "FETCH_PROJECT_DETAIL_SUCCESS",
    FETCH_PROJECT_DETAIL_FAIL = "FETCH_PROJECT_DETAIL_FAIL",
    FETCH_PROJECT_DETAIL_RESET_STATE = "FETCH_PROJECT_DETAIL_RESET_STATE",

    UPDATE_PROJECT = "UPDATE_PROJECT",
    UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS",
    UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL",
    UPDATE_PROJECT_RESET_STATE = "UPDATE_PROJECT_RESET_STATE",

    FETCH_SINGLE_PROJECT_TASK = "FETCH_SINGLE_PROJECT_TASK",
    FETCH_SINGLE_PROJECT_TASK_SUCCESS = "FETCH_SINGLE_PROJECT_TASK_SUCCESS",
    FETCH_SINGLE_PROJECT_TASK_FAIL = "FETCH_SINGLE_PROJECT_TASK_FAIL",
    FETCH_SINGLE_PROJECT_TASK_RESET_STATE = "FETCH_SINGLE_PROJECT_TASK_RESET_STATE",

    CREATE_PROJECT_TASK = "CREATE_PROJECT_TASK",
    CREATE_PROJECT_TASK_SUCCESS = "CREATE_PROJECT_TASK_SUCCESS",
    CREATE_PROJECT_TASK_FAIL = "CREATE_PROJECT_TASK_FAIL",
    CREATE_PROJECT_TASK_RESET_STATE = "CREATE_PROJECT_TASK_RESET_STATE",

    FETCH_SINGLE_TASK = "FETCH_SINGLE_TASK",
    FETCH_SINGLE_TASK_SUCCESS = "FETCH_SINGLE_TASK_SUCCESS",
    FETCH_SINGLE_TASK_FAIL = "FETCH_SINGLE_TASK_FAIL",
    FETCH_SINGLE_TASK_RESET_STATE = "FETCH_SINGLE_TASK_RESET_STATE",

    FETCH_JOB_TYPE = "FETCH_JOB_TYPE",
    FETCH_JOB_TYPE_SUCCESS = "FETCH_JOB_TYPE_SUCCESS",
    FETCH_JOB_TYPE_FAIL = "FETCH_JOB_TYPE_FAIL",
    FETCH_JOB_TYPE_RESET_STATE = "FETCH_JOB_TYPE_RESET_STATE",

    UPDATE_PROJECT_TASK = "UPDATE_PROJECT_TASK",
    UPDATE_PROJECT_TASK_SUCCESS = "UPDATE_PROJECT_TASK_SUCCESS",
    UPDATE_PROJECT_TASK_FAIL = "UPDATE_PROJECT_TASK_FAIL",
    UPDATE_PROJECT_TASK_RESET_STATE = "UPDATE_PROJECT_TASK_RESET_STATE",

    SET_ERROR = "SET_ERROR",
    

    FETCH_ASSIGNED_TASK = "FETCH_ASSIGNED_TASK",
    SET_ASSIGNED_TASK = "SET_ASSIGNED_TASK",
    FETCH_ASSIGNED_TASK_COUNT = "FETCH_ASSIGNED_TASK_COUNT",
    SET_ASSIGNED_TASK_COUNT = "SET_ASSIGNED_TASK_COUNT",
    FETCH_CUSTOMER_PROJECT = "FETCH_CUSTOMER_PROJECT",
    SET_CUSTOMER_PROJECT = "SET_CUSTOMER_PROJECT",
    FETCH_TASK_COUNT_BASED_ON_USER = "FETCH_TASK_COUNT_BASED_ON_USER",
    SET_TASK_COUNT_BASED_ON_USER = "SET_TASK_COUNT_BASED_ON_USER",
    FETCH_PROJECT_STATUS = "FETCH_PROJECT_STATUS",
    SET_PROJECT_STATUS = "SET_PROJECT_STATUS",
    FETCH_PROJECT_MONTH_DATA = "FETCH_PROJECT_MONTH_DATA",
    SET_PROJECT_MONTH_DATA = "SET_PROJECT_MONTH_DATA",
    FETCH_COMPLETE_PROJECT = "FETCH_COMPLETE_PROJECT",
    SET_COMPLETE_PROJECT = "SET_COMPLETE_PROJECT",
    GET_PROJECTS_WITH_VALUE = "GET_PROJECTS_WITH_VALUE",
    SET_PROJECTS_WITH_VALUE = "SET_PROJECTS_WITH_VALUE",
    GET_ONGOING_PROJECT_WITH_VALUE = "GET_ONGOING_PROJECT_WITH_VALUE",
    SET_ONGOING_PROJECT_WITH_VALUE = "SET_ONGOING_PROJECT_WITH_VALUE",
    GET_COMPLETE_PROJECT_WITH_VALUE = "GET_COMPLETE_PROJECT_WITH_VALUE",
    SET_COMPLETE_PROJECT_WITH_VALUE = "SET_COMPLETE_PROJECT_WITH_VALUE",
    GET_FUNNEL_DATA = "GET_FUNNEL_DATA",
    SET_FUNNEL_DATA = "SET_FUNNEL_DATA",
    FETCH_PROJECT_BY_LOCATION = "FETCH_PROJECT_BY_LOCATION",
    SET_PROJECT_BY_LOCATION = "SET_PROJECT_BY_LOCATION",

    DELETE_PROJECT = "DELETE_PROJECT",
    DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS",
    DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL",

    FETCH_PROJECT_LOCATION="FETCH_PROJECT_LOCATION",
    SET_PROJECT_LOCATION="SET_PROJECT_LOCATION",

    FETCH_PROJECT_TASK_STATUS="FETCH_PROJECT_TASK_STATUS",
    SET_PROJECT_TASK_STATUS="SET_PROJECT_TASK_STATUS",

    FETCH_JOB_STATUS="FETCH_JOB_STATUS",
    SET_JOB_STATUS="SET_JOB_STATUS",

    FETCH_JOB_PRIORITY="FETCH_JOB_PRIORITY",
    SET_JOB_PRIORITY="SET_JOB_PRIORITY",

    BULK_UPDATE_PROJECT_TASK = "BULK_UPDATE_PROJECT_TASK",
    BULK_UPDATE_PROJECT_TASK_SUCCESS = "BULK_UPDATE_PROJECT_TASK_SUCCESS",
    BULK_UPDATE_PROJECT_TASK_FAIL = "BULK_UPDATE_PROJECT_TASK_FAIL",

    
}


