import { ActionTypes } from "./constants";
import { fetchWarehouse, fetchWarehouseDetails, createWarehouse, updateWarehouse, deleteWarehouse,updateWarehouseStock,fetchWarehouseActions,fetchWarehouseComments,fetchWarehouseTransaction } from "helpers";
import { put, takeEvery, call, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
    fetchWarehouseResponse,
    createWarehouseResponse,
    updateWarehouseResponse,
    deleteWarehouseResponse,
    fetchSingleWarehouseResponse,
    updateWarehouseStockResponse,
    fetchWarehouseActionResponse,
    fetchWarehouseCommentsResponse,
    fetchCommentTransactionResponse,
} from './actions'


function* _fetchWarehouses(params: any): SagaIterator {
    try {
        const response = yield call(fetchWarehouse, {
            page: params.payload.data.page,
            limit: params.payload.data.limit,
            sortBy: params.payload.data.sortBy,
            sort: params.payload.data.sort,
            searchBy: params.payload.data?.searchBy,
            type:    params.payload.data?.type
        });
        const data = response.data;
        yield put(
            fetchWarehouseResponse(ActionTypes.FETCH_WAREHOUSE_LIST_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchWarehouseResponse(ActionTypes.FETCH_WAREHOUSE_LIST_FAILURE, error)
        );
    }
}


function* _fetchWarehouseStockCommentHistory(params: any): SagaIterator {
    try {
        const response = yield call(fetchWarehouseComments, {
            page: params.payload.data.page,
            limit: params.payload.data.limit,
            sortBy: params.payload.data.sortBy,
            sort: params.payload.data.sort,
            searchBy: params.payload.data?.searchBy,
            warehouse:params.payload.data?.warehouse,
            filter: params.payload.data?.filter
        });
        const data = response.data;
        yield put(
            fetchWarehouseCommentsResponse(ActionTypes.FETCH_STOCK_COMMENT_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchWarehouseCommentsResponse(ActionTypes.FETCH_STOCK_COMMENT_FAILURE, error)
        );
    }
}

function* _fetchWarehouseCommentTransaction(params: any): SagaIterator {
    try {
        const response = yield call(fetchWarehouseTransaction, {
            page: params.payload.data.page,
            limit: params.payload.data.limit,
            sortBy: params.payload.data.sortBy,
            sort: params.payload.data.sort,
            transaction: params.payload.data?.transaction,
            warehouse:params.payload.data?.warehouse
        });
        const data = response.data;
        yield put(
            fetchCommentTransactionResponse(ActionTypes.FETCH_COMMENT_TRANSACTION_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchCommentTransactionResponse(ActionTypes.FETCH_COMMENT_TRANSACTION_FAILURE, error)
        );
    }
}


function* _fetchWarehouseActions(params: any): SagaIterator {
    try {
        const response = yield call(fetchWarehouseActions, {
            title: params.payload.data.title
        });
        const data = response.data;
        yield put(
            fetchWarehouseActionResponse(ActionTypes.FETCH_WAREHOUSE_ACTIONS_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchWarehouseActionResponse(ActionTypes.FETCH_WAREHOUSE_ACTIONS_FAILURE, error)
        );
    }
}


function* _createWarehouse({ payload }: any): SagaIterator {
    try {
        const response = yield call(createWarehouse, {
            warehouse: payload.data.warehouse,
        });
        const data = response.data;
        yield put(
            createWarehouseResponse(ActionTypes.CREATE_WAREHOUSE_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(createWarehouseResponse(ActionTypes.CREATE_WAREHOUSE_FAILURE, error));
    }
}


function* _getWarehouseDetails({ payload }: any): SagaIterator {
    try {
        const response = yield call(fetchWarehouseDetails, {
            id: payload.data.id,
            page: payload.data.page,
            limit: payload.data.limit,
            sortBy: payload.data.sortBy,
            sort: payload.data.sort,
            searchBy: payload.data?.searchBy,
        });
        const data = response.data;
        yield put(
            fetchSingleWarehouseResponse(
                ActionTypes.FETCH_SINGLE_WAREHOUSE_SUCCESS,
                data
            )
        );
    } catch (error: any) {
        yield put(
            fetchSingleWarehouseResponse(
                ActionTypes.FETCH_SINGLE_WAREHOUSE_FAILURE,
                error
            )
        );
    }
}


function* _updateWarehouse({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateWarehouse, {
            id: payload.data.id,
            warehouse: payload.data.warehouse,
        });
        const data = response.data;
        yield put(
            updateWarehouseResponse(ActionTypes.UPDATE_WAREHOUSE_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(updateWarehouseResponse(ActionTypes.UPDATE_WAREHOUSE_FAILURE, error));
    }
}


function* _updateWarehouseStocks({ payload }: any): SagaIterator {
    try {

        const response = yield call(updateWarehouseStock, {
            id: payload.data.id,
            formData: payload.data.formData,
        });
        const data = response.data;
        yield put(
            updateWarehouseStockResponse(ActionTypes.UPDATE_STOCK_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(updateWarehouseStockResponse(ActionTypes.UPDATE_STOCK_FAILURE, error));
    }
}

function* _deleteWarehouse({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteWarehouse, {
            id: payload.data.id,
        });
        const data = response.data;
        yield put(
            deleteWarehouseResponse(ActionTypes.DELETE_WAREHOUSE_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(deleteWarehouseResponse(ActionTypes.DELETE_WAREHOUSE_FAILURE, error));
    }
}


function* watchWarehouse() {
    yield takeEvery(ActionTypes.FETCH_WAREHOUSE_LIST, _fetchWarehouses);
    yield takeEvery(ActionTypes.CREATE_WAREHOUSE, _createWarehouse);
    yield takeLatest(ActionTypes.FETCH_SINGLE_WAREHOUSE, _getWarehouseDetails);
    yield takeEvery(ActionTypes.UPDATE_WAREHOUSE, _updateWarehouse);
    yield takeEvery(ActionTypes.DELETE_WAREHOUSE, _deleteWarehouse);
    yield takeEvery(ActionTypes.UPDATE_STOCK,_updateWarehouseStocks);
    yield takeEvery(ActionTypes.FETCH_WAREHOUSE_ACTIONS,_fetchWarehouseActions)
    yield takeEvery(ActionTypes.FETCH_STOCK_COMMENT_LIST,_fetchWarehouseStockCommentHistory)
    yield takeEvery(ActionTypes.FETCH_COMMENT_TRANSACTION_LIST,_fetchWarehouseCommentTransaction)
}

export default watchWarehouse;
